var ks = ks || {};


//collections slider
// -----------------------------------------------------------------

ks.collectionsSlider = function () {
  var $slider = $(".js_collectionsSlider");

  if (!$slider.length) {
    return;
  }

  var mySwiper = new Swiper(".js_collectionsSlider", {
    loop: true,
    direction: "horizontal",
    autoplay: {
      delay: 3000,
    },
    grabCursor: true,

    // Pagination
    pagination: {
      el: ".pag1",
      clickable: true,
    },

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });
};

ks.notificationsSlider = function () {
    var $slider = $(".js_notificationsSlider");
  
    if (!$slider.length) {
      return;
    }
  
    var mySwiper2 = new Swiper(".js_notificationsSlider", {
      loop: true,
      direction: "horizontal",
      autoplay: {
        delay: 3000,
      },
      grabCursor: true,
  
      // Pagination
      pagination: {
        el: ".pag2",
        clickable: true,
      },

      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });
};

$(document).ready(function () {
  ks.collectionsSlider();
  ks.notificationsSlider();
});
